import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Community = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Trusted by companies
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent={'center'}>
        {[
          'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/fidelitylife-color.svg',
          'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/partnerslife-color.svg',
          'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/chubb-color.svg',
          'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/nib-color.svg',
          'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/aia-color.svg',
          // 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/fidelity-logo-removebg.png',
          // 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/nib-logo-removebg.png',
          // 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/partnerslife-logo.png',
          // 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/CHUBB_Logo_Black_RBG.png',
          // 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/aia-logo-red.svg',
        ].map((item, i) => (
          <Box maxWidth={180} marginTop={2} marginRight={4} key={i}>
            <Box
              component="img"
              height={1}
              width={1}
              src={item}
              alt="..."
              sx={{
                filter:
                  theme.palette.mode === 'dark'
                    ? 'brightness(0) invert(0.7)'
                    : 'none',
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Community;
